import Vue from "vue";
import CreateTab from "@/views/Admin/Lookalike/Create/createTab.vue";
import Alertize from "@/components/Alertize.vue";
import i18n from "@/plugins/i18n";
import { isEmpty, isNull, isUndefined } from "lodash";
import LookALike from "@/views/Admin/Lookalike/Dependencies/lookalikeForm.vue";
import Segments from "@/views/Admin/Lookalike/Dependencies/segmentForm.vue";

export default Vue.extend({
    name: "ListLookalike",
    props: {},
    components: {
        Alertize,
        CreateTab,
        LookALike,
        Segments
    },
    data: () => ({
        currentTab: 0,
        items: [],
        enabledSegments: true,
        enabledLookalike: false,
        lookalikeData:{
            id: null,
            segment_total_user: null
        }
    }),
    created(){},
    mounted(){

    },
    computed:{
        getSegmentUsers(){
            return this.lookalikeData;
        },
        getItems() {
			this.items = [
				{
					key: 0,
					tab: i18n.t('lookalike.tabs.lookalike'),
					disabled: this.enabledLookalike,
				},
				{
					key: 1,
					tab: i18n.t('lookalike.tabs.segment'),
					disabled: this.enabledSegments,
				}
			];
			return this.items;
		},
    },
    methods:{

        updateSelectedTabIndex(index: number = 0) {
            this.currentTab = index;
        },

        currentTabSelected(item: Number, selected: Number) {
            return item === selected;
        },

        async redirectTo(campaignsId?: any, index?: any) {
			let route: any = { name: "CampaignsIndex" };
			return this.$router.push(route);
		},

        async updateTotalUsers(data: any){
            this.lookalikeData.id = data.id;
            this.lookalikeData.segment_total_users = data.segment_total_users;
            this.enabledSegments = false;
            this.enabledLookalike = true;
            this.updateSelectedTabIndex(1);
        }   
    },
    watch: {
    }

});