
//@ts-nocheck
import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import {
	isRequired,
	isNumber
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import CardActions from "@/components/Content/CardAction.vue";
import { SegmentCreate } from "@/interfaces/look_a_like";

export default Vue.extend({
	name: "SegmentForm",
	props: {
		segmentUser:{
        	type: Object,
        	required: true
    	},
	},
	components: {
		Alertize,
		CardTextField,
		CardActions
	},
	data: () => ({
		title: "Create",
		valid: true,
		countries: [],
		campaigns: [],
        segment: {} as SegmentCreate,
		id_segment: null
	}),
	created() {},
	async mounted() {
		await this.loadData();
	},
	computed: {
		...mapGetters("profile", ["account"]),

		getCountrys(){
			return this.countries;
		},

        getCampaigns(){
			return this.campaigns;
		},

		getRules() {
			return {
				isRequired,
				isNumber
			};
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getMaxUser(){
			return this.segmentUser.segment_total_users;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", ["fetchData"]),
		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("lookalike", ["active"]),

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		async loadData(){
			this.id_segment = this.segmentUser.id;
			this.segment.segment_size = this.segmentUser.segment_total_users;
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "cancel":
					this.handleCancel();
					break;
				case "save":
					this.handleSubmit();
					break;
			}
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.active({idSegment: this.id_segment, entity: this.segment})
				.then(async (resp) => {
					await this.setLoadingData();
				})
				.catch(async (err) => {
					await this.setLoadingData();
				});
			} catch (error) {
				await this.setLoadingData();
			}
		},

		handleCancel() {
			this.$router.push({ name: "LookalikeIndex" });
		},

		async fetchCountries(){
			this.setLoadingField(true);
			await this.fetchData({type: "countries"})
			.then(async (resp) => {
				await this.parseDataCountry(resp);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.setLoadingField(false);
			});
		},

		async parseDataCountry(countries: any){
			countries.forEach(c => {
				let {title, items} = c;
				this.countries.push({header: title});
				items.forEach(i => {
					let {id, value} = i;
					this.countries.push({group: title, id: id, value: value});
				});
			});
		}
	},
	watch:{}
});
