
//@ts-nocheck
import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import {
	isRequired,
	isNumber
} from "@/services/rule-services";
import { getError } from "@/utils/resolveObjectArray";
import { TypeLoading } from "@/interfaces/loading";
import { mapActions, mapGetters } from "vuex";
import CardActions from "@/components/Content/CardAction.vue";
import { lookalikeCreate } from "@/interfaces/look_a_like";
import { isEmpty, orderBy } from "lodash";
import { getDefaultSortingOption } from "@/utils/filter-global";

export default Vue.extend({
	name: "LookalikeForm",
	props: {},
	components: {
		Alertize,
		CardAutocomplete,
		CardActions
	},
	data: () => ({
		title: "Create",
		valid: true,
        lookalike: {
			campaign_id: null,
			country_id: null
		} as lookalikeCreate,
		resources:{
			campaigns: [],
			countries: []
		},
		filters:{},
		options: getDefaultSortingOption("id"),
	}),
	created() {},
	async mounted() {
	},
	computed: {
		...mapGetters("profile", ["account"]),

		getCountrys(){
			return this.resources.countries;
		},

        getCampaigns(){
			return this.resources.campaigns;
		},

		getRules() {
			return {
				isRequired,
				isNumber
			};
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("person", ["fetchData"]),
		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("campaign", ["list"]),
		...mapActions("lookalike", ["create"]),

		getError(index: any) {
			return getError(this.getErrors, index);
		},

		async validate() {
			return await this.$refs.form.validate();
		},

		async handleAction(action: any) {
			switch (action.type) {
				case "save":
					this.handleSubmit();
					break;
				case "cancel":
					this.handleCancel();
					break;
			}
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				await this.create({data: this.lookalike})
				.then(async (response) =>{
					const {id, segment_total_users} = response;
					await this.sendTotalUsers({id: id, segment_total_users: segment_total_users});
					await this.setLoadingData();
				})
				.catch(async (err) => {
					await this.setLoadingData();
				});
			} catch (error) {
				await this.setLoadingData();
			}
		},

		handleCancel() {
			this.$router.push({ name: "LookalikeIndex" });
		},

		async fetchCampaign(){
			if(!isEmpty(this.resources.campaigns)) return;
			await this.setLoadingField(TypeLoading.loading);
			await this.list({filters: this.filters, options: this.options})
			.then(async(res) => {
				const val = orderBy(res, ['id'], ['desc']);
				this.resources.campaigns = val;
				await this.setLoadingField();
			})
			.catch(async(err) => {
				await this.setLoadingField(); 
			});
		},

		async fetchCountries(){
			if(!isEmpty(this.resources.countries)) return;
			this.setLoadingField(true);
			await this.fetchData({type: "countries", setID: true})
			.then(async (resp) => {
				await this.parseDataCountry(resp);
				this.setLoadingField(false);
			})
			.catch(async (err) => {
				this.setLoadingField(false);
			});
		},

		async parseDataCountry(countries: any){
			countries.forEach(c => {
				let {title, items} = c;
				this.resources.countries.push({header: title});
				items.forEach(i => {
					let {id, value} = i;
					this.resources.countries.push({group: title, id: id, value: value});
				});
			});
		},

		async sendTotalUsers(data: number){
			this.$emit("update-total-user", data);
		}
	},
	watch:{
	}
});
